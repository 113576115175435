import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";

export default function Library() {
  return (
    <Layout>
      <div className="library">
        <h1 className="heading">Library</h1>

        <p className="library__text">
          <strong>Welcome to Our Library: A Haven for Knowledge Seekers</strong>
          <br />
          <br />
          Our library opens the doors to a world of limitless possibilities,
          inviting you to embark on a journey of discovery, enlightenment, and
          wide scope for imagination.
          <br />
          <br />
          Entering the library, you are greeted by a warm ambience, the scent of
          aged papers, and the gentle hum of whispered conversations.
          <br />
          <br />
          The school library is fully air conditioned and is well equipped with
          over 12800 books related to various disciplines.
          <br />
          <br />
          The shelves that adorn our library are home to an extensive collection
          of books, carefully curated to cater to a wide range of interests and
          passions. From classic literature to contemporary bestsellers, from
          profound philosophical treatises to captivating tales of adventure and
          mystery, our collection spans across genres and epochs.
          <br />
          <br />
          But a library is more than just a repository of books. Within our
          walls, students will find comfortable reading nooks where they can
          lose themselves in the pages of a cherished novel, cozy study corners
          to dive into the depths of academic research, and inviting meeting
          spaces where book discussions and activities take place.
        </p>

        <div className="library__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12 col-md-6 col-xl-4">
                <img className="library__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
